import { privateApi } from '@/plugins/axios'
import { convertTicketDates } from '@/api/ApiHelper'

export const getUser = async() => {
  try {
    const { data } = await privateApi.get('/user')
    return { data, error: null }
  } catch ({ response }) {
    return {
      data: null,
      error: {
        text: response.data,
        status: response.status
      }
    }
  }
}

export const checkUsername = async(username) => {
  try {
    const { data } = await privateApi.get(`/user/check/${username}`)
    return { data, error: null }
  } catch ({ response }) {
    return {
      data: null,
      error: {
        text: response.data,
        status: response.status
      }
    }
  }
}

export const setUserData = async(userData) => {
  try {
    const { data } = await privateApi.post('/user', userData)
    return { data, error: null }
  } catch ({ response }) {
    return {
      data: null,
      error: {
        text: response.data,
        status: response.status
      }
    }
  }
}

export const getUserFiles = async() => {
  try {
    const { data } = await privateApi.get('/user/files')
    return { data, error: null }
  } catch ({ response }) {
    return {
      data: null,
      error: {
        text: response.data,
        status: response.status
      }
    }
  }
}

export const getSingleUserFile = async(userToken, fileId) => {
  const params = {
    token: userToken
  }

  try {
    const { data } = await privateApi.get(`/download/${fileId}`, { params })
    return { data, error: null }
  } catch ({ response }) {
    return {
      data: null,
      error: {
        text: response.data,
        status: response.status
      }
    }
  }
}

export const addFile = async(category, file) => {
  const config = {
    headers: { 'Content-Type': 'multipart/form-data' }
  }

  try {
    const { data } = await privateApi.post(`/user/files/${category}`, file, config)
    return { data, error: null }
  } catch ({ response }) {
    return {
      data: null,
      error: {
        text: response.data,
        status: response.status
      }
    }
  }
}

export const deleteFile = async(fileId) => {
  try {
    const { data } = await privateApi.post(`/user/files/${fileId}/delete`)
    return { data, error: null }
  } catch ({ response }) {
    return {
      data: null,
      error: {
        text: response.data,
        status: response.status
      }
    }
  }
}

export const getTickets = async({ filterByPhase, filterByStatus }) => {
  const params = {
    fbp: filterByPhase,
    fbs: filterByStatus
  }

  try {
    const { data } = await privateApi.get('/user/tickets', { params })
    return { data, error: null }
  } catch ({ response }) {
    return {
      data: null,
      error: {
        text: response.data,
        status: response.status
      }
    }
  }
}

export const getSingleTicket = async(ticketId) => {
  try {
    const { data } = await privateApi.get(`/user/ticket/${ticketId}`)
    return { data, error: null }
  } catch ({ response }) {
    return {
      data: null,
      error: {
        text: response.data,
        status: response.status
      }
    }
  }
}

export const createTicket = async(ticket) => {
  convertTicketDates(ticket)
  try {
    const { data } = await privateApi.post('/user/ticket', ticket)
    return { data, error: null }
  } catch ({ response }) {
    return {
      data: null,
      error: {
        text: response.data,
        status: response.status
      }
    }
  }
}

export const updateTicket = async(ticketId, ticket) => {
  convertTicketDates(ticket)
  try {
    const { data } = await privateApi.post(`/user/ticket/${ticketId}`, ticket)
    return { data, error: null }
  } catch ({ response }) {
    return {
      data: null,
      error: {
        text: response.data,
        status: response.status
      }
    }
  }
}

export const deleteTicket = async(ticketId) => {
  try {
    const { data } = await privateApi.post(`/user/ticket/${ticketId}/delete`)
    return { data, error: null }
  } catch ({ response }) {
    return {
      data: null,
      error: {
        text: response.data,
        status: response.status
      }
    }
  }
}

export const setPhaseRating = async(ticketId, phase, rating) => {
  try {
    const { data } = await privateApi.post(`/user/rate/${ticketId}/${phase}/${rating}`)
    return { data, error: null }
  } catch ({ response }) {
    return {
      data: null,
      error: {
        text: response.data,
        status: response.status
      }
    }
  }
}

export const getTicketRate = async(ticketId) => {
  try {
    const { data } = await privateApi.get(`/user/ticket/rate/${ticketId}`)
    return { data, error: null }
  } catch ({ response }) {
    return {
      data: null,
      error: {
        text: response.data,
        status: response.status
      }
    }
  }
}

export const setTicketNote = async(ticketId, ticket) => {
  try {
    const { data } = await privateApi.post(`/user/ticket/${ticketId}/note`, ticket)
    return { data, error: null }
  } catch ({ response }) {
    return {
      data: null,
      error: {
        text: response.data,
        status: response.status
      }
    }
  }
}

export const getTicketNotes = async(ticketId) => {
  try {
    const { data } = await privateApi.get(`/user/ticket/${ticketId}/notes`)
    return { data, error: null }
  } catch ({ response }) {
    return {
      data: null,
      error: {
        text: response.data,
        status: response.status
      }
    }
  }
}

export const getCompanies = async() => {
  try {
    const { data } = await privateApi.get('/user/companies')
    return { data, error: null }
  } catch ({ response }) {
    return {
      data: null,
      error: {
        text: response.data,
        status: response.status
      }
    }
  }
}

export const getCompanyReviews = async({ pageNumber, pageSize, filterByCompany, filterByJobTitle, sortBy }) => {
  const params = {
    p: pageNumber,
    pp: pageSize,
    fbc: filterByCompany,
    fbj: filterByJobTitle,
    sb: sortBy
  }

  try {
    const { data } = await privateApi.get('/user/review/company', { params })
    return { data, error: null }
  } catch ({ response }) {
    return {
      data: null,
      error: {
        text: response.data,
        status: response.status
      }
    }
  }
}

export const getReviewsForCompany = async(companyId, {
  pageNumber,
  pageSize,
  filterByJobTitle,
  sortBy
}) => {
  const params = {
    p: pageNumber,
    pp: pageSize,
    fbj: filterByJobTitle,
    sb: sortBy
  }

  try {
    const { data } = await privateApi.get(`/user/review/company/${companyId}/reviews`, { params })
    return { data, error: null }
  } catch ({ response }) {
    return {
      data: null,
      error: {
        text: response.data,
        status: response.status
      }
    }
  }
}

export const getCompany = async(companyId) => {
  try {
    const { data } = await privateApi.get(`/user/review/company/${companyId}`)
    return { data, error: null }
  } catch ({ response }) {
    return {
      data: null,
      error: {
        text: response.data,
        status: response.status
      }
    }
  }
}

export const getDashboardReviews = async({ pageNumber, pageSize, filterByCompany, filterByJobTitle, sortBy }) => {
  const params = {
    p: pageNumber,
    pp: pageSize,
    fbc: filterByCompany,
    fbj: filterByJobTitle,
    sb: sortBy
  }

  try {
    const { data } = await privateApi.get('/user/review/dashboard', { params })
    return { data, error: null }
  } catch ({ response }) {
    return {
      data: null,
      error: {
        text: response.data,
        status: response.status
      }
    }
  }
}

export const getIndividualReviews = async({ pageNumber, pageSize, filterByCompany, filterByJobTitle, sortBy }) => {
  const params = {
    p: pageNumber,
    pp: pageSize,
    fbc: filterByCompany,
    fbj: filterByJobTitle,
    sb: sortBy
  }

  try {
    const { data } = await privateApi.get('/user/review/individual', { params })
    return { data, error: null }
  } catch ({ response }) {
    return {
      data: null,
      error: {
        text: response.data,
        status: response.status
      }
    }
  }
}

export const createApplicationReview = async(ticketId, reviewApplication) => {
  try {
    const { data } = await privateApi.post(`/user/review/application/${ticketId}`, reviewApplication)
    return { data, error: null }
  } catch ({ response }) {
    return {
      data: null,
      error: {
        text: response.data,
        status: response.status
      }
    }
  }
}

export const createScreeningInterviewReview = async(ticketId, reviewsInterviews) => {
  try {
    const { data } = await privateApi.post(`/user/review/interview/${ticketId}`, reviewsInterviews)
    return { data, error: null }
  } catch ({ response }) {
    return {
      data: null,
      error: {
        text: response.data,
        status: response.status
      }
    }
  }
}

export const createOfferReview = async(ticketId, reviewsOffer) => {
  try {
    const { data } = await privateApi.post(`/user/review/offer/${ticketId}`, reviewsOffer)
    return { data, error: null }
  } catch ({ response }) {
    return {
      data: null,
      error: {
        text: response.data,
        status: response.status
      }
    }
  }
}

export const getApplicationReview = async(ticketId) => {
  try {
    const { data } = await privateApi.get(`/user/review/application/${ticketId}`)
    return { data, error: null }
  } catch ({ response }) {
    return {
      data: null,
      error: {
        text: response.data,
        status: response.status
      }
    }
  }
}

export const getInterviewReview = async(ticketId) => {
  try {
    const { data } = await privateApi.get(`/user/review/interview/${ticketId}`)
    return { data, error: null }
  } catch ({ response }) {
    return {
      data: null,
      error: {
        text: response.data,
        status: response.status
      }
    }
  }
}

export const getOfferReview = async(ticketId) => {
  try {
    const { data } = await privateApi.get(`/user/review/offer/${ticketId}`)
    return { data, error: null }
  } catch ({ response }) {
    return {
      data: null,
      error: {
        text: response.data,
        status: response.status
      }
    }
  }
}

export const importTickets = async(file) => {
  const formData = new FormData()
  formData.append('fileUpload', file)

  try {
    const { data } = await privateApi.post('/user/import', formData)
    return { data, error: null }
  } catch ({ response }) {
    return {
      data: null,
      error: {
        text: response.data,
        status: response.status
      }
    }
  }
}
