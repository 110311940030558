<template>
  <v-app id="inspire">
    <TheHeader v-if="isPublicPage && !isPrivacyTermsPages" />
    <TheDrawer v-else />
    <v-main :class="['content', { 'drawer-padding' : !isPublicPage || isPrivacyTermsPages }]">
      <transition mode="out-in" appear name="fade-transition">
        <router-view class="view" />
      </transition>
    </v-main>
    <TheFooter v-if="isPublicPage && !isPrivacyTermsPages" />
  </v-app>
</template>

<script>
import TheFooter from '@/components/TheFooter'
import TheHeader from '@/components/TheHeader'
import TheDrawer from '@/components/TheDrawer'

export default {
  name: 'TargetPage',
  components: {
    TheFooter,
    TheHeader,
    TheDrawer
  },
  data() {
    return {
      isPublicPage: false,
      isPrivacyTermsPages: false
    }
  },
  watch: {
    $route() {
      this.isPublicPage = !this.$route.meta.requiresAuth
      this.isPrivacyTermsPages = this.$route.name === 'Privacy' || this.$route.name === 'Terms'
    }
  },
  mounted() {
    this.isPublicPage = !this.$route.meta.requiresAuth
    this.isPrivacyTermsPages = this.$route.name === 'Privacy' || this.$route.name === 'Terms'
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.content {
  background-color: var(--background-dashboard-color);
}

.view {
  height: 100%;
}

.drawer-padding {
  margin-left: 70px;

  @include breakpoint-reverse(small) {
    margin-left: 0;
  }
}
</style>
