<template>
  <div class="app-radio-group">
    <span class="radio-group-label">
      {{ title }}
    </span>
    <v-radio-group
      v-model="radioGroupValue"
      row
      hide-details
      dense
      class="radio-group-input"
    >
      <v-radio
        :value="true"
        label="Yes"
        :ripple="false"
        color="var(--background-primary-color)"
      />
      <v-radio
        :value="false"
        label="No"
        :ripple="false"
        color="var(--background-primary-color)"
      />
    </v-radio-group>
  </div>
</template>

<script>
export default {
  name: 'AppRadioGroup',
  props: {
    title: {
      type: String,
      required: true
    },
    value: {
      validator: prop => typeof prop === 'boolean' || prop === null,
      required: true
    }
  },
  data() {
    return {
      radioGroupValue: this.value
    }
  },
  watch: {
    value(value) {
      this.radioGroupValue = value
    },
    radioGroupValue() {
      this.$emit('update:value', this.radioGroupValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.radio-group-label {
  font-size: var(--font-size-base-sm);
}

.radio-group-input {
  &::v-deep.v-input--selection-controls {
    margin-top: 10px;
  }
}
</style>
