<template>
  <div>
    <span v-if="label" class="label">{{ label }}</span>
    <v-combobox
      v-model="inputValue"
      solo
      outlined
      flat
      dense
      hide-no-data
      return-object
      hide-details="auto"
      item-text="name"
      item-value="name"
      :label="label"
      :placeholder="placeholder"
      :error-messages="errorMessages"
      :items="items"
      :required="required"
      :menu-props="{
        bottom: true,
        offsetY: true,
        contentClass: 'app-select-menu'
      }"
      class="app-autocomplete"
      @change="$emit('change', inputValue)"
      @input="$emit('input', inputValue)"
      @blur="$emit('blur')"
    >
      <template #item="{ item }">
        <v-list-item-content>
          <v-list-item-title v-text="item.name" />
        </v-list-item-content>
      </template>
      <template v-slot:append>
        <img
          width="13"
          height="13"
          src="img/icons/select-arrow.svg"
          alt=""
        >
      </template>
    </v-combobox>
  </div>
</template>

<script>
export default {
  name: 'AppAutocomplete',
  props: {
    label: {
      type: String,
      required: false,
      default: ''
    },
    items: {
      type: Array,
      required: true
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    value: {
      type: [String, Object],
      required: false,
      default: () => {}
    },
    errorMessages: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      inputValue: this.value
    }
  },
  watch: {
    value(value) {
      this.inputValue = value
    },
    inputValue() {
      this.$emit('update:value', this.inputValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.label {
  margin-bottom: 5px;
  font-weight: var(--font-weight-light);
  font-size: var(--font-size-base-sm);
}

.app-autocomplete::v-deep {
  .v-input__slot,
  .v-input__control {
    min-height: 34px !important;
  }

  .v-text-field__details {
    position: absolute;
    bottom: -22px;
  }

  input::placeholder {
    color: var(--placeholder-text-color);
  }

  &.v-text-field--outlined {
    fieldset {
      border: 1px solid var(--primary-border-color);
    }
  }

  &.v-select--is-menu-active {
    fieldset {
      border-bottom: 1px solid var(--secondary-border-color);
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &.error--text {
    fieldset {
      border: 2px solid var(--error-color);
    }
  }
}
</style>
