<template>
  <v-menu
    ref="menu"
    v-model="isDatePickerOpen"
    :return-value.sync="pickerDate"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <AppInput
        :value="formatISOdate(pickerDate)"
        :error-messages="dateError"
        placeholder="MM/DD/YYYY"
        type="text"
        :label="label"
        is-required
        readonly
        v-bind="attrs"
        color="var(--background-primary-color)"
        :on="on"
        @change="validate"
      />
    </template>
    <v-date-picker
      v-model="pickerDate"
      no-title
      scrollable
      @input="saveDate"
    >
      <v-spacer />
      <v-btn
        text
        @click="isDatePickerOpen = false"
      >
        Cancel
      </v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
import { formatISOdate } from '@/services/dateService'

export default {
  name: 'AppDatePicker',
  props: {
    date: {
      type: String,
      required: false,
      default: ''
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    dateError: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      isDatePickerOpen: false,
      pickerDate: this.date
    }
  },
  watch: {
    date(value) {
      this.pickerDate = value
    }
  },
  methods: {
    formatISOdate,
    validate() {
      this.$emit('validate', this.date)
    },
    saveDate() {
      this.$refs.menu.save(this.pickerDate)
      this.$emit('update:date', this.pickerDate)
    }
  }
}
</script>
