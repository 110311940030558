<template>
  <div class="app-range-slider">
    <span class="range-slider-label">
      {{ title }}
    </span>
    <v-slider
      v-model="rangeSliderValue"
      :tick-labels="labels"
      :steps="steps"
      step="1"
      :max="steps"
      hide-details
      dense
      track-color="var(--secondary-text-color)"
      track-fill-color="var(--secondary-text-color)"
      class="slider-input"
    />
  </div>
</template>

<script>
export default {
  name: 'AppRangeSlider',
  props: {
    title: {
      type: String,
      required: true
    },
    value: {
      type: [String, Number],
      required: true
    },
    labels: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      rangeSliderValue: this.value
    }
  },
  computed: {
    steps() {
      return this.labels.length - 1
    }
  },
  watch: {
    value(value) {
      this.rangeSliderValue = value
    },
    rangeSliderValue() {
      this.$emit('update:value', this.rangeSliderValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.app-range-slider {
  width: 225px;
}

.range-slider-label {
  font-size: var(--font-size-base-sm);
}

.slider-input {
  &::v-deep {
    .v-slider__track-container {
      height: 1px;
    }

    .v-slider__thumb {
      width: 18px;
      height: 18px;
      border: 1px solid var(--primary-border-color) !important;
      background-color: var(--background-secondary-color) !important;

      &::before {
        display: none;
      }
    }

    .v-slider__tick-label {
      margin-top: 8px;
      font-size: var(--font-size-base-sm);
    }

    .v-slider__tick {
      height: 0 !important;
    }
  }
}
</style>
