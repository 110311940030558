<template>
  <footer class="footer">
    <!-- Copyright -->
    <v-container>
      <v-row class="align-center">
        <v-col cols="12" md="2">
          <div class="logo">
            <img
              class="img"
              src="/img/logo.png"
              height="49"
              width="49"
              alt="Unghosted logo"
            >
          </div>
        </v-col>
        <v-col cols="12" md="6">
          <div class="links">
            <router-link class="link" :to="{ name: 'Privacy' }">
              Privacy Policy
            </router-link>
            <router-link class="link" :to="{ name: 'Terms' }">
              Terms & Conditions
            </router-link>
          </div>
        </v-col>
        <v-col cols="12" md="4">
          <!-- Social networks icons -->
          <div col="12" md="2" class="socials">
            <span class="socials-title"> Social Media Links:</span>
            <a href="https://twitter.com/#" title="Our Twitter page" target="_blank">
              <v-icon class="social--icon">mdi-twitter</v-icon>
            </a>
            <a href="https://www.instagram.com/#/" title="Our Instagram account" target="_blank">
              <v-icon class="social--icon">mdi-instagram</v-icon>
            </a>
            <a href="https://www.youtube.com/channel/#" title="Our Youtube channel" target="_blank">
              <v-icon class="social--icon">mdi-youtube</v-icon>
            </a>
          </div>

          <div class="copyright">
            © UnGhosted {{ currentYear }}
          </div>
        </v-col>
      </v-row>
    </v-container>
  </footer>
</template>

<script>
export default {
  name: 'TheFooter',
  data() {
    return {
      currentYear: new Date().getFullYear()
    }
  }
}
</script>

<style lang="scss" scoped>

.footer {
  margin-top: auto;
  padding: 60px 0 120px;
  background-color: var(--background-primary-color) !important;
}

.link,
.socials,
.copyright {
  color: var(--navigation-link-color);
}

.logo {
  .img {
    width: 49px;
    height: 49px;
  }
}

.links {
  display: flex;
  flex-direction: column;
  color: var(--navigation-link-color);

  .link {
    margin-bottom: 14px;
    font-size: var(--font-size-base-lg);
  }
}

.socials {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 14px;
  font-size: var(--font-size-lg);
  line-height: 1;

  i {
    color: var(--navigation-link-color);
  }
}
</style>
