<template>
  <div class="page-header">
    <span :class="['page-header__title', isDark ? 'page-header__title_dark' : '']">
      <slot />
    </span>
    <div class="actions">
      <slot name="action" />
      <AppButton
        width="134"
        height="34"
        outlined
        :color="isDark ? 'var(--contrast-text-color)' : 'var(--button-primary-color)'"
        class="page-header__sign-out-button"
        @click="signOut"
      >
        Log Out
      </AppButton>
    </div>
  </div>
</template>

<script>
import Fire from '@/plugins/firebase'
import { mapActions } from 'vuex'

export default {
  name: 'PageHeader',
  props: {
    isDark: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    ...mapActions(['setUser']),
    async signOut() {
      await Fire.auth().signOut()
      this.setUser(null)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.page-header {
  display: flex;
  align-items: center;

  &__title {
    position: relative;
    z-index: 1;
    margin-left: 47px;
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-xlg);

    &::after {
      content: "";
      position: absolute;
      left: -12px;
      top: 25%;
      z-index: -1;
      display: block;
      width: calc(100% + 24px);
      height: 24px;
      background-color: var(--background-highlight-color);
      transform: translateY(25%);
    }

    &_dark {
      color: var(--contrast-text-color);

      &::after {
        background-color: #16284d;
      }
    }

    @include breakpoint-reverse(medium) {
      margin-left: 0;
    }

    @include breakpoint-reverse(small) {
      margin-left: 12px;
    }
  }

  .actions {
    position: absolute;
    right: 50px;
    display: flex;
    align-items: center;
    gap: 24px;
  }

  &__sign-out-button {
    &::v-deep .text {
      font-weight: var(--font-weight-normal);
      font-size: var(--font-size-base-lg);
      line-height: var(--line-height-xs);
      text-shadow: none;
    }

    @include breakpoint-reverse(small) {
      display: none;
    }
  }
}
</style>
