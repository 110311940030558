<template>
  <div>
    <span v-if="label" class="label">{{ label }}</span>
    <v-text-field
      v-model="inputValue"
      :class="{ 'alternative' : alt }"
      class="app-input"
      dense
      flat
      autocomplete="off"
      hide-details="auto"
      outlined
      solo
      :height="height"
      :color="color"
      :background-color="backgroundColor"
      :error="error"
      :error-messages="errorMessages"
      :placeholder="placeholder"
      :append-icon="appendIcon"
      :type="type"
      :required="isRequired"
      :readonly="readonly"
      v-on="on"
      @keyup.enter="$emit('pressEnter')"
      @click:append="$emit('click:append')"
      @input="$emit('input', inputValue)"
      @blur="$emit('blur')"
    >
      <span slot="prepend-inner" class="prefix">
        <slot />
      </span>
    </v-text-field>
  </div>
</template>

<script>
export default {
  name: 'AppInput',
  props: {
    value: {
      type: [Number, String],
      required: false,
      default: null
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    height: {
      type: String,
      required: false,
      default: '34'
    },
    color: {
      type: String,
      required: false,
      default: 'var(--secondary-text-color)'
    },
    backgroundColor: {
      type: String,
      required: false,
      default: 'var(--input-bg-color)'
    },
    error: {
      type: Boolean,
      default: false
    },
    errorMessages: {
      type: Array,
      required: false,
      default: () => []
    },
    alt: {
      type: Boolean,
      require: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    appendIcon: {
      type: String,
      required: false,
      default: ''
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    on: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data() {
    return {
      inputValue: this.value
    }
  },
  watch: {
    value(value) {
      this.inputValue = value
    },
    inputValue() {
      this.$emit('update:value', this.inputValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.label {
  margin-bottom: 5px;
  font-weight: var(--font-weight-light);
  font-size: var(--font-size-base-sm);
}

.app-input::v-deep {
  .v-input__slot {
    padding: 0 10px !important;
    min-height: 34px !important;
    background-color: var(--background-secondary-color) !important;
  }

  input::placeholder {
    color: var(--placeholder-text-color);
  }
}

.app-input::v-deep.v-text-field--outlined:not(.error--text) {
  fieldset {
    border-color: var(--primary-border-color);
  }
}
</style>
