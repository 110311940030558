<template>
  <header class="header">
    <v-container class="header-content">
      <div class="logo">
        <router-link :to="{ name: 'Home' }">
          <img
            class="img"
            src="/img/logo.png"
            height="49"
            width="49"
            alt="Unghosted logo"
          >
        </router-link>
      </div>
      <div class="links">
        <router-link class="link" :to="{ name: 'HelpContact' }">
          Help/Contact
        </router-link>

        <v-skeleton-loader
          v-if="getAuthLoader"
          width="150"
          type="button"
          class="btn-loader"
        />
        <template v-else>
          <AppButton
            v-if="getUser"
            :to="{ name: 'Dashboard' }"
            width="fit-content"
            color="var(--navigation-link-color)"
            outlined
          >
            Dashboard
          </AppButton>
          <AppButton
            v-else
            :to="{ name: 'LogIn' }"
            width="fit-content"
            color="var(--navigation-link-color)"
            outlined
          >
            Log In
          </AppButton>
        </template>
      </div>
    </v-container>
  </header>
</template>

<script>
import Fire from '@/plugins/firebase'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'TheHeader',

  data() {
    return {
      menu: false
    }
  },
  computed: {
    ...mapGetters(['getUser', 'getAuthLoader']),
    websiteUrl() {
      return process.env.VUE_APP_WEBSITE_URL
    }
  },
  methods: {
    ...mapActions(['setUser']),
    async handlerClickLogout() {
      await Fire.auth().signOut()
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.header {
  z-index: 1;
  padding: 4px 0;
  box-shadow: none !important;
  background-color: var(--background-primary-color) !important;
}

.header-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.logo {
  .img {
    width: 49px;
    height: 49px;
  }
}

.links {
  display: flex;
  align-items: center;
  gap: 35px;
  color: var(--navigation-link-color);

  @include breakpoint-reverse(small) {
    gap: 10px;
    margin-left: 10px;
  }

  .link {
    font-size: var(--font-size-base-lg);

    @include breakpoint-reverse(small) {
      margin-right: 0;
    }
  }

  .btn-loader::v-deep {
    .v-skeleton-loader__button {
      width: 100%;
    }
  }
}
</style>
