<template>
  <div>
    <span v-if="label" class="label">{{ label }}</span>
    <v-textarea
      v-model="inputValue"
      solo
      flat
      outlined
      :rows="rows"
      :placeholder="placeholder"
      hide-details="auto"
      class="app-textarea"
      @input="$emit('input', inputValue)"
    />
  </div>
</template>

<script>
export default {
  name: 'AppTextarea',
  props: {
    value: {
      type: String,
      required: false,
      default: ''
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    rows: {
      type: String,
      required: false,
      default: '3'
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      inputValue: this.value
    }
  },
  watch: {
    value(value) {
      this.inputValue = value
    },
    inputValue() {
      this.$emit('update:value', this.inputValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.label {
  margin-bottom: 5px;
  font-weight: var(--font-weight-light);
  font-size: var(--font-size-base-sm);
}

.app-textarea::v-deep {
  .v-input__slot {
    padding: 0 10px !important;
    min-height: 34px !important;
    background-color: var(--background-secondary-color) !important;
  }

  & input::placeholder {
    color: var(--placeholder-text-color);
  }
}

.app-textarea::v-deep.v-text-field--outlined:not(.error--text) {
  fieldset {
    border-color: var(--primary-border-color);
  }
}
</style>
