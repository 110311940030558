<template>
  <div>
    <TargetPage />
    <p v-if="error" class="er">
      Something went wrong, please try again later
    </p>
  </div>
</template>

<script>
import TargetPage from './views/TargetPage'
import { mapActions, mapGetters } from 'vuex'
import api from '@/api'
export default {
  name: 'App',
  components: {
    TargetPage
  },
  data() {
    return {
      error: null,
      user: null,
      targetPage: null,
      currentUser: null
    }
  },
  computed: {
    ...mapGetters(['getTargetPage', 'getUser'])
  },
  async created() {
    if (this.$route.name && this.$route.name !== 'LogIn' && this.$route.name !== 'Home') {
      this.setTargetPage(this.$route.fullPath)
    }
    try {
      this.setAuthLoader(true)

      this.$firebase.auth().onAuthStateChanged(async user => {
        if (!user && this.getUser) {
          this.setUser(null)
          this.$router.push({ name: 'LogIn' })
        }

        if (!user) {
          if (this.$route.meta.requiresAuth) {
            this.$router.push({ name: 'Home' })
          }

          this.setAuthLoader(false)
          return
        }

        const { data, error } = await api.users.getUser()

        if (error) return

        this.setUser(data)

        this.setAuthLoader(false)

        if (this.getTargetPage) {
          const targetPage = this.getTargetPage
          this.setTargetPage(null)

          if (targetPage !== this.$route.fullPath) {
            this.$router.push(targetPage)
            return
          }

          return
        }

        if (this.$route.name === 'LogIn' || this.$route.name === 'SignUp') {
          this.$router.push({ name: 'Dashboard' })
        }
      })
    } catch (e) {
      console.error(e.message)
      this.setAuthLoader(false)
    }
  },
  methods: {
    ...mapActions(['setTargetPage', 'setUser', 'setAuthLoader'])
  }
}
</script>

<style lang="scss">
@import "@/assets/style/global.scss";

.loader-main {
  display: block !important;
  margin: 20% auto;
  color: var(--background-highlight-color);
}
</style>
