import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    targetPage: null,
    companiesList: [],
    authLoader: false
  },
  getters: {
    getUser: state => state.user,
    getTargetPage: state => state.targetPage,
    getCompaniesList: state => state.companiesList,
    getAuthLoader: state => state.authLoader
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user
    },
    SET_TARGET_PAGE(state, targetPage) {
      state.targetPage = targetPage
    },
    SET_COMPANIES_LIST(state, companiesList) {
      state.companiesList = companiesList
    },
    SET_AUTH_LOADER(state, authLoader) {
      state.authLoader = authLoader
    }
  },
  actions: {
    setUser({ commit }, user) {
      commit('SET_USER', user)
    },
    setTargetPage({ commit }, targetPage) {
      commit('SET_TARGET_PAGE', targetPage)
    },
    setCompaniesList({ commit }, companiesList) {
      commit('SET_COMPANIES_LIST', companiesList)
    },
    setAuthLoader({ commit }, authLoader) {
      commit('SET_AUTH_LOADER', authLoader)
    }
  }
})
