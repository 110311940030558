import ax from 'axios'
import firebase from '@/plugins/firebase'

// External
const externalApi = ax.create()

externalApi.interceptors.response.use(
  response => response,
  error => {
    if (error && error.response && error.response.status !== 404) {
      console.error(error.response.data)
    }

    return Promise.reject(error)
  }
)

// Private API
const privateApi = ax.create({
  baseURL: `${process.env.VUE_APP_API_URL}`,
  timeout: 15000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
})

privateApi.interceptors.request.use(
  async config => {
    const currentUser = await firebase.auth().currentUser
    const token = await currentUser.getIdToken()
    config.headers['X-Auth-Token'] = token

    return config
  },

  error => {
    return Promise.reject(error)
  }
)

privateApi.interceptors.response.use(
  response => response,
  error => {
    if (error && error.response && error.response.status !== 404) {
      console.error(error.response.data)
    }

    return Promise.reject(error)
  }
)

export { externalApi, privateApi }
