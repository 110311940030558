<template>
  <div>
    <span v-if="label" class="label">{{ label }}</span>
    <v-select
      v-model="inputValue"
      solo
      outlined
      flat
      dense
      :item-text="itemText"
      :item-value="itemId"
      hide-details
      :height="height"
      :items="items"
      :placeholder="placeholder"
      :required="required"
      :menu-props="{
        bottom: true,
        offsetY: true,
        contentClass: 'app-select-menu'
      }"
      class="app-select"
      @change="$emit('change', inputValue)"
    >
      <template v-slot:append>
        <img
          width="13"
          height="13"
          src="img/icons/select-arrow.svg"
          alt=""
        >
      </template>
    </v-select>
  </div>
</template>

<script>
export default {
  name: 'AppSelect',
  props: {
    label: {
      type: String,
      required: false,
      default: ''
    },
    height: {
      type: String,
      required: false,
      default: '34'
    },
    itemText: {
      type: String,
      required: false,
      default: ''
    },
    itemId: {
      type: String,
      required: false,
      default: ''
    },
    items: {
      type: Array,
      required: true
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    value: {
      type: String,
      required: false,
      default: '34'
    }
  },
  data() {
    return {
      inputValue: this.value
    }
  },
  watch: {
    value(value) {
      this.inputValue = value
    },
    inputValue() {
      this.$emit('update:value', this.inputValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.label {
  margin-bottom: 5px;
  font-weight: var(--font-weight-light);
  font-size: var(--font-size-base-sm);
}

.app-select::v-deep {
  .v-input__slot,
  .v-input__control {
    min-height: 34px !important;
  }

  & input::placeholder {
    color: var(--placeholder-text-color);
  }

  &.v-text-field--outlined {
    fieldset {
      border: 1px solid var(--primary-border-color);
    }
  }

  &.v-select--is-menu-active {
    fieldset {
      border-bottom: 1px solid var(--secondary-border-color);
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}
</style>
