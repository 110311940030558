import Vue from 'vue'

import * as VueGoogleMaps from 'vue2-google-maps'
import AppButton from '@/components/AppButton'
import AppInput from '@/components/AppInput'
import AppSelect from '@/components/AppSelect'
import AppTextarea from '@/components/AppTextarea'
import PageHeader from '@/components/PageHeader'
import AppInputFile from '@/components/AppInputFile'
import AppDatePicker from '@/components/AppDatePicker'
import AppRadioGroup from '@/components/AppRadioGroup'
import AppRangeSlider from '@/components/AppRangeSlider'
import AppAutocomplete from '@/components/AppAutocomplete'

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_API_KEY,
    libraries: 'places'
  }
})
Vue.component('AppButton', AppButton)
Vue.component('AppInput', AppInput)
Vue.component('AppSelect', AppSelect)
Vue.component('AppTextarea', AppTextarea)
Vue.component('PageHeader', PageHeader)
Vue.component('AppInputFile', AppInputFile)
Vue.component('AppDatePicker', AppDatePicker)
Vue.component('AppRadioGroup', AppRadioGroup)
Vue.component('AppRangeSlider', AppRangeSlider)
Vue.component('AppAutocomplete', AppAutocomplete)
