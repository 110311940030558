<template>
  <div v-click-outside="closeDrawer">
    <v-app-bar class="mobile-bar" height="64px" color="var(--background-primary-color)">
      <v-app-bar-nav-icon color="var(--contrast-text-color)" @click.stop="openDrawer" />
      <AppButton
        width="134"
        height="34"
        outlined
        color="var(--contrast-text-color)"
        class="sign-out-button"
        @click="signOut"
      >
        Log Out
      </AppButton>
    </v-app-bar>
    <v-navigation-drawer
      mini-variant-width="70"
      :mini-variant.sync="isDrawerMini"
      :expand-on-hover="!isDrawerOpen"
      permanent
      absolute
      width="217"
      class="drawer"
      :class="{ 'open': !isDrawerMini }"
      bottom
      dark
    >
      <section class="logo">
        <router-link :to="{ name: 'Home' }">
          <img
            :class="{ mini: isDrawerMini }"
            class="img"
            src="/img/logo.png"
            height="49"
            width="49"
            alt="Unghosted logo"
          >
        </router-link>
      </section>
      <v-list class="menu-items" dense nav>
        <v-list-item
          v-for="item in menuItems"
          :key="item.title"
          :to="item.to"
          class="menu-item"
          link
          @click="closeDrawer"
        >
          <v-list-item-icon>
            <v-icon class="item-icon">
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title class="item-title">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-list
        class="second-menu-items"
        dense
        nav
      >
        <v-list-item
          v-for="item in secondMenuItems"
          :key="item.title"
          :to="item.to"
          class="second-menu-item"
          link
          @click="closeDrawer"
        >
          <v-list-item-content>
            <v-list-item-title class="item-title">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import Fire from '@/plugins/firebase'
import { mapActions } from 'vuex'

export default {
  name: 'TheDrawer',
  data() {
    return {
      isDrawerOpen: false,
      isDrawerMini: true,
      menuItems: [
        {
          title: 'Dashboard',
          icon: 'mdi-view-dashboard-outline',
          to: { name: 'Dashboard' }
        },
        { title: 'Reviews', icon: 'mdi-star-outline', to: { name: 'Reviews' } },
        {
          title: 'Resources',
          icon: 'mdi-attachment',
          to: { name: 'Resources' }
        },
        {
          title: 'My Account',
          icon: 'mdi-account-outline',
          to: { name: 'Account' }
        },
        {
          title: 'My Archives',
          icon: 'mdi-folder-outline',
          to: { name: 'Archives' }
        }
      ],
      secondMenuItems: [
        {
          title: 'Help/Contact',
          to: { name: 'HelpContact' }
        },
        {
          title: 'Privacy Policy',
          to: { name: 'Privacy' }
        },
        {
          title: 'Terms & Conditions',
          to: { name: 'Terms' }
        }
      ]
    }
  },
  methods: {
    ...mapActions(['setUser']),
    openDrawer() {
      this.isDrawerOpen = true
    },
    closeDrawer() {
      this.isDrawerOpen = false
    },
    async signOut() {
      await Fire.auth().signOut()
      this.setUser(null)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/style/mixins.scss";

.drawer::v-deep {
  position: fixed;
  z-index: 100;
  background-color: var(--background-primary-color) !important;

  .v-list-item__icon {
    margin: 8px !important;
  }

  &.open {
    box-shadow: 10px 0 20px rgba(0, 0, 0, 0.5);
    transform: translateX(0%) !important;

    .second-menu-items {
      display: block;
    }
  }

  @include breakpoint-reverse(small) {
    transform: translateX(-100%) !important;
  }
}

.mobile-bar {
  @include breakpoint(medium) {
    display: none;
  }
}

.logo {
  display: block;
  margin: 78px auto 45px;
  width: fit-content;
}

.menu-item {
  margin-bottom: 30px !important;

  .item-title,
  .item-icon i {
    font-size: var(--font-size-lg) !important;
  }

  .item-title {
    overflow: initial;
  }
}

.second-menu-items {
  display: none;
  margin-top: 70px;
}

.second-menu-item {
  margin-bottom: 15px !important;

  .item-title {
    margin-left: 18px;
    font-size: var(--font-size-base-sm) !important;
  }

  &.v-list-item--link::before {
    background-color: initial;
  }
}

.sign-out-button {
  margin-left: auto;
}
</style>
