import Fire from './plugins/firebase'

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('./views/Home.vue'),
    meta: { title: 'Home' }
  },
  {
    path: '/login',
    name: 'LogIn',
    component: () => import('./views/Login.vue'),
    meta: {
      title: 'Login',
      hideForAuth: true
    }
  },
  {
    path: '/verify-email-notice',
    name: 'VerifyEmailNotice',
    component: () => import('./views/VerifyEmailNotice.vue'),
    meta: {
      title: 'Verify email notice'
    }
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('./views/ForgotPassword.vue'),
    meta: {
      title: 'Forgot password',
      hideForAuth: true
    }
  },
  {
    path: '/auth-action',
    name: 'AuthAction',
    component: () => import('./views/AuthAction.vue'),
    meta: {
      title: 'Auth action'
    }
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: () => import('./views/Login.vue'),
    meta: {
      title: 'Sign up',
      hideForAuth: true
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('./views/Dashboard.vue'),
    meta: {
      title: 'Dashboard',
      requiresAuth: true
    }
  },
  {
    path: '/reviews',
    name: 'Reviews',
    component: () => import('./views/Reviews.vue'),
    meta: {
      title: 'Reviews',
      requiresAuth: true
    }
  },
  {
    path: '/reviews/company/:id',
    name: 'CompanyPage',
    component: () => import('./views/CompanyPage.vue'),
    meta: {
      title: 'Company Page',
      requiresAuth: true
    }
  },
  {
    path: '/archives',
    name: 'Archives',
    component: () => import('./views/Archives.vue'),
    meta: {
      title: 'Archives',
      requiresAuth: true
    }
  },
  {
    path: '/resources',
    name: 'Resources',
    component: () => import('./views/Resources.vue'),
    meta: {
      title: 'Resources',
      requiresAuth: true
    }
  },
  {
    path: '/help-contact',
    name: 'HelpContact',
    component: () => import('./views/HelpContact.vue'),
    meta: {
      title: 'Help/Contact',
      requiresAuth: true
    }
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('./views/Account.vue'),
    meta: {
      title: 'Account',
      requiresAuth: true
    }
  },
  {
    path: '/privacy-policy',
    name: 'Privacy',
    component: () => import('./views/Privacy.vue'),
    meta: {
      title: 'Privacy Policy',
      requiresAuth: false
    }
  },
  {
    path: '/terms-and-conditions',
    name: 'Terms',
    component: () => import('./views/Terms.vue'),
    meta: {
      title: 'Terms & Conditions',
      requiresAuth: false
    }
  },
  {
    path: '*',
    redirect: { name: 'Home' }
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

router.beforeEach(async(to, from, next) => {
  const currentUser = await Fire.getCurrentUser()

  if (!currentUser && to.meta.requiresAuth) {
    next({ name: 'LogIn' })
    return
  }

  if (currentUser && !currentUser.emailVerified && to.meta.requiresAuth) {
    next({ name: 'VerifyEmailNotice' })
    return
  }

  if (currentUser && to.meta.hideForAuth) {
    next({ name: 'Dashboard' })
    return
  }

  next()
})

export default router
