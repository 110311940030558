<template>
  <div>
    <span v-if="label" class="label">{{ label }}</span>
    <div class="input-wrapper">
      <v-file-input
        v-model="inputValue"
        small-chips
        dense
        flat
        hide-details="auto"
        outlined
        solo
        clearable
        :accept="accept"
        :required="isRequired"
        :placeholder="placeholder"
        :loading="loading"
        prepend-icon=""
        color="var(--text-color)"
        class="app-file-input"
        @change="$emit('change', $event)"
        @click:clear="$emit('delete', inputValue)"
      >
        <template v-slot:selection>
          <v-chip
            small
            label
            color="var(--button-primary-color)"
            text-color="var(--contrast-text-color)"
          >
            {{ inputValue.name }}
          </v-chip>
        </template>
      </v-file-input>
      <AppButton
        v-if="inputValue && inputValue.fileId"
        height="34"
        class="download-button"
        color="var(--button-primary-color)"
        @click="$emit('download', inputValue)"
      >
        Download file
      </AppButton>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppInputFile',
  props: {
    value: {
      type: [File, Object],
      required: false,
      default: null
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    error: {
      type: Boolean,
      required: false,
      default: false
    },
    errorMessages: {
      type: Array,
      required: false,
      default: () => []
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    isRequired: {
      type: Boolean,
      required: false,
      default: false
    },
    accept: {
      type: String,
      required: false,
      default: ''
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      inputValue: this.value
    }
  },
  watch: {
    value(value) {
      this.inputValue = value
    },
    inputValue() {
      this.$emit('update:value', this.inputValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.label {
  margin-bottom: 5px;
  font-weight: var(--font-weight-light);
  font-size: var(--font-size-base-sm);
}

.input-wrapper {
  display: flex;
  gap: 10px;
}

.app-file-input::v-deep {
  .v-input__slot {
    padding: 0 10px !important;
    min-height: 34px !important;
    background-color: var(--background-secondary-color) !important;
  }

  .v-file-input__text--placeholder {
    color: var(--placeholder-text-color);
  }
}

.app-file-input::v-deep.v-text-field--outlined:not(.error--text) {
  fieldset {
    border-color: var(--primary-border-color);
  }
}

.download-button {
  &::v-deep .text {
    font-weight: var(--font-weight-normal);
    font-size: var(--font-size-base-sm);
    line-height: var(--line-height-xs);
    text-shadow: none;
    color: var(--contrast-text-color);
  }
}
</style>
