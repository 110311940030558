<template>
  <v-btn
    tile
    class="app-button"
    :class="{ large: isLarge }"
    :disabled="isDisabled"
    :color="color"
    :icon="icon"
    :loading="loading"
    :to="to"
    :dark="dark"
    :outlined="outlined"
    :width="width"
    :height="height"
    @click="handlerClick"
  >
    <span class="text"><slot /></span>
  </v-btn>
</template>

<script>
export default {
  name: 'AppButton',
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: null
    },
    color: {
      type: String,
      required: false,
      default: 'none'
    },
    icon: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
    to: {
      type: Object,
      required: false,
      default: () => {}
    },
    dark: {
      type: Boolean,
      required: false,
      default: false
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false
    },
    width: {
      type: String,
      required: false,
      default: ''
    },
    height: {
      type: String,
      required: false,
      default: ''
    }
  },
  data() {
    return {
      loader: null
    }
  },
  computed: {
    isDisabled() {
      return !!this.seconds || this.disabled
    },
    isLarge() {
      return this.height >= 48
    }
  },
  methods: {
    handlerClick() {
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss" scoped>
.app-button::v-deep {
  border-radius: var(--border-radius-button);

  .text {
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-h1);
    letter-spacing: normal;
    text-shadow: var(--text-shadow-default);
    text-transform: initial;
  }

  &.v-size--default {
    padding: 8px 16px;
  }

  &.large {
    border-radius: var(--border-radius-button-lg);

    .text {
      font-size: var(--font-size-lg);
    }
  }
}

</style>
